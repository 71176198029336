import React from 'react';
import {SubscriptionFrequency} from '@wix/ambassador-ecom-v1-checkout/types';
import {ILocaleKeys, useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {MAX_ITEM_OPTION_LENGTH, NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE} from '../../constants';
import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {LineItemOptionModel} from '../../../../domain/models/LineItemOption.model';
import {useControllerProps} from '../../Widget/ControllerContext';
import {SubscriptionModel} from '../../../../domain/models/Subscription.model';
import {ItemLayout} from './ItemLayout';
import {ItemName} from './ItemName/ItemName';
import {ItemPrice} from './ItemPrice/ItemPrice';
import {ItemOptions} from './ItemOptions/ItemOptions';
import {ItemSubscription} from './ItemSubscription/ItemSubscription';
import {ItemOutOfStock} from './ItemOutOfStock/ItemOutOfStock';
import {classes} from './LineItem.st.css';
import {ItemImage} from './ItemImage/ItemImage';

export enum LineItemDataHooks {
  LineItemWrapper = 'LineItemDataHooks.LineItemWrapper',
  Name = 'LineItemDataHooks.Name',
  ComparePrice = 'LineItemDataHooks.ComparePrice',
  Price = 'LineItemDataHooks.Price',
  Subscription = 'LineItemDataHooks.Subscription',
  Options = 'LineItemDataHooks.Options',
  OutOfStock = 'LineItemDataHooks.OutOfStock',
  Image = 'LineItemDataHooks.Image',
  DefaultImage = 'LineItemDataHooks.DefaultImage',
}

export const LineItemRow = ({
  lineItem,
  useLayoutForLongPrice,
}: {
  lineItem: LineItemModel;
  useLayoutForLongPrice: boolean;
}) => {
  const localeKeys = useLocaleKeys();
  const {removeLineItem} = useControllerProps().checkoutStore;

  const getQuantityAsLineItemOption = () => {
    return new LineItemOptionModel({
      value: `${lineItem.quantity}`,
      title: localeKeys.checkout.order_summary.items.quantity(),
    });
  };

  const getSalePriceAriaLabel = () => {
    return lineItem.prices.comparePrice
      ? localeKeys.checkout.orderSummary.salePrice.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        })
      : localeKeys.checkout.orderSummary.price.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        });
  };

  return (
    <li data-hook={LineItemDataHooks.LineItemWrapper}>
      <ItemLayout
        headerWithColumns={!useLayoutForLongPrice}
        Aside={<ItemImage itemMedia={lineItem.media} />}
        Header1={<ItemName name={lineItem.productName} />}
        Header2={
          <ItemPrice
            alignToRight={!useLayoutForLongPrice}
            formattedPrice={lineItem.prices.lineItemPrice.formattedAmount}
            priceAriaLabel={getSalePriceAriaLabel()}
            formattedComparePrice={lineItem.prices.comparePrice?.formattedAmount}
            comparePriceAriaLabel={
              lineItem.prices.comparePrice
                ? localeKeys.checkout.orderSummary.regularPrice.ariaLabel({
                    price: lineItem.prices.comparePrice?.formattedAmount,
                  })
                : undefined
            }
          />
        }
        Content={
          <div className={classes.itemData}>
            {lineItem.subscription && (
              <ItemSubscription subscription={getSubscriptionData(lineItem.subscription, localeKeys)} />
            )}
            <ItemOptions
              itemId={lineItem.id}
              options={[getQuantityAsLineItemOption(), ...lineItem.options]}
              maxOptionValLength={MAX_ITEM_OPTION_LENGTH}
              collapseSettings={{
                numberOfOptionsToCollapseAfter: NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE + 1,
                showMoreTitle: localeKeys.checkout.order_summary.items.more_details(),
                showLessTitle: localeKeys.checkout.order_summary.items.lessDetails(),
              }}
            />
          </div>
        }
        Footer={
          lineItem.quantity === 0 ? (
            <ItemOutOfStock
              message={localeKeys.checkout.order_summary.item_out_of_stock_error()}
              removeButtonText={localeKeys.checkout.order_summary.removeFromCartError.cta()}
              removeButtonOnClick={() => void removeLineItem(lineItem.id)}
            />
          ) : undefined
        }
      />
    </li>
  );
};

const getSubscriptionData = (
  subscription: SubscriptionModel,
  localeKeys: ILocaleKeys
): {name: string; duration: string} => {
  if (subscription.isAutoRenewal) {
    return {
      name: subscription.name,
      duration: localeKeys.checkout.page_plan_autorenew(),
    };
  }

  const frequency = getFrequencyText(subscription.frequency, subscription.billingCycles, localeKeys);

  if (subscription.billingCycles > 1) {
    return {
      name: subscription.name,
      duration: localeKeys.checkout.page_plan_duration_description_plural({
        numberOfFrequencyUnits: subscription.billingCycles,
        frequencyUnitSingular: frequency,
      }),
    };
  }

  return {
    name: subscription.name,
    duration: localeKeys.checkout.page_plan_duration_description_singular({
      duration: undefined,
      frequencyUnitSingular: frequency,
    }),
  };
};

const getFrequencyText = (frequency: SubscriptionFrequency, billingCycles: number, localeKeys: ILocaleKeys) => {
  switch (frequency) {
    case SubscriptionFrequency.DAY: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_days() : localeKeys.checkout.page_plan_day();
    }
    case SubscriptionFrequency.WEEK: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_weeks() : localeKeys.checkout.page_plan_week();
    }
    case SubscriptionFrequency.MONTH: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_months() : localeKeys.checkout.page_plan_month();
    }
    case SubscriptionFrequency.YEAR: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_years() : localeKeys.checkout.page_plan_year();
    }
  }
};
